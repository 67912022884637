/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import router from "./../router";
import services from "./../services";
import Utils from "../Utils";

const strict = true;

const getDefaultState = () => {
  return {
    activeProject: {},
    projectAssets: []
  };
};

const state = getDefaultState();

const getters = {};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },

  SET_PROJECT_ASSETS(state, assets) {
    state.projectAssets = assets;
  },

  SET_ACTIVE_PROJECT(state, project) {
    state.activeProject = project;
  },

  ADD_ASSET(state, asset) {
    state.projectAssets.push(asset);
  },

  REMOVE_ASSET(state, assetId) {
    state.projectAssets = state.projectAssets.filter(
      asset => asset._id !== assetId
    );
  }
};

const actions = {
  createResearchProject({ commit }, { name, clientId, branchId }) {
    const payload = {
      name,
      branchId,
      type: "researchonly",
      status: "closed",
      projectClosedAt: new Date().toISOString(),
      step: 0
    };

    return services.ProjectService.createProject(clientId, payload)
      .then(project => {
        return Promise.resolve(project);
      })
      .catch(error => {
        console.log(error);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },

  createProjectAsset({ state, commit }, { projectId, asset }) {
    const videoInfo = {
      file: asset.file,
      name: asset.name,
      type: "other",
      status: "draft",
      category: "-",
      requiredRespondents: 0
    };
    return services.ProjectService.createProjectAsset(projectId, videoInfo)
      .then(asset => {
        commit("ADD_ASSET", asset);
      })
      .catch(error => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },

  deleteProjectAsset({ commit }, { assetId, projectId }) {
    return services.ProjectService.deleteProjectAsset(projectId, assetId)
      .then(response => {
        commit("REMOVE_ASSET", assetId);
      })
      .catch(error => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },

  retrieveProjectAssets({ state, commit }, { projectId }) {
    return services.ProjectService.retrieveProjectAssets(projectId)
      .then(response => {
        commit("SET_PROJECT_ASSETS", response);
      })
      .catch(error => {
        commit("SET_PROJECT_ASSETS", []);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },

  setProjectThumbnail({ state, commit }, { clientId, projectId, thumbnail }) {
    return services.ProjectService.setProjectThumbnail(
      clientId,
      projectId,
      thumbnail
    )
      .then(response => {})
      .catch(error => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },

  async createProjectAnalysis(
    { commit },
    { clientId, projectId, analysisFile }
  ) {
    console.log(analysisFile);
    const payload = {
      file: analysisFile
    };
    return services.ProjectService.createProjectAnalysis(
      clientId,
      projectId,
      payload
    )
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  retrieveProjectAnalysis({ commit }, { clientId, projectId }) {
    return services.ProjectService.retrieveProjectAnalysis(clientId, projectId)
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },

  retrieveProjectData({ commit }, { clientId, projectId }) {
    return services.ProjectService.retrieveProjctData(clientId, projectId)
      .then(response => {
        const exportData = `data:text/json;charset=utf-8,${escape(
          JSON.stringify(response)
        )}`;
        const filename = `${projectId}.json`;
        const downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", exportData);
        downloadAnchorNode.setAttribute("download", filename);

        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      })
      .catch(error => {
        console.log(error);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },

  // Should move a project from one client to another
  moveProjectToClient({commit},{srcClientId,dstClientId,projectId}){
    return services.ProjectService.moveProjectToClient(srcClientId,dstClientId,projectId)
    .catch(error => {
      console.log(error);
      checkRedirectionByStatus(error.status, commit);
      commit(
        "error/SET_ERROR",
        { status: error.status },
        {
          root: true
        }
      );
      return Promise.reject();
    });
  },

  cloneProjectToClient({commit},{srcClientId,dstClientId,projectId}){
    return services.ProjectService.cloneProjectToClient(srcClientId,dstClientId,projectId)
    .catch(error => {
      console.log(error);
      checkRedirectionByStatus(error.status, commit);
      commit(
        "error/SET_ERROR",
        { status: error.status },
        {
          root: true
        }
      );
      return Promise.reject();
    });
  },

  deleteProjectByClientId({ commit }, { clientId, projectId }) {
    return services.ProjectService.deleteProjectByClientId(
      clientId,
      projectId
    ).catch(error => {
      checkRedirectionByStatus(error.status, commit);
      commit(
        "error/SET_ERROR",
        { status: error.status },
        {
          root: true
        }
      );
      return Promise.reject();
    });
  }
};

function checkRedirectionByStatus(status, commit) {
  switch (status) {
    case Utils.ERRORS.UNAUTHORIZED:
      commit("auth/SET_IS_LOGGED_IN", false, { root: true });
      router.replace({ name: "Login" }).catch(() => {});
      break;
    case Utils.ERRORS.NOT_FOUND:
    case Utils.ERRORS.METHOD_NOT_ALLOWED:
      router
        .replace({ name: "Clients", params: { showErrorDialog: true } })
        .catch(() => {});
      break;
    default:
      break;
  }
}

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions
};
