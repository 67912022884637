import swaggerClient from "./../plugins/swagger-client";

export default class ProjectService {
  createProject(clientId, payload) {
    return swaggerClient.swagger.then(client => {
      return client.apis.projects
        .createProject(
          { clientId },
          swaggerClient.buildRequestBody(payload),
          {}
        )
        .then(response => {
          return response.body;
        })
        .catch(error => {
          return Promise.reject(error);
        });
    });
  }

  setProjectThumbnail(clientId, projectId, thumbnail) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.projects.setProjectThumbnail(
          { clientId, projectId },
          swaggerClient.buildRequestBody({
            file: thumbnail
          }),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  createProjectAsset(projectId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.projects.createProjectAsset(
          { projectId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deleteProjectAsset(projectId, assetId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.projects.deleteProjectAsset(
          { projectId, assetId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  createProjectAnalysis(clientId, projectId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis["project analytics"].createProjectAnalysis(
          { clientId, projectId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveProjectAssets(projectId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.projects.retrieveProjectAssets(
          { projectId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveNorms() {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.utils.retrieveNormsV11(
          {},
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveProjectAnalysis(clientId, projectId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis["project analytics"].retrieveProjectAnalysis(
          { clientId, projectId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveProjctData(clientId, projectId) {
    return swaggerClient.swagger.then(client => {
      return client.apis.projects
        .retrieveProjectData(
          { clientId, projectId },
          swaggerClient.buildRequestBody(),
          {}
        )
        .then(response => {
          return response.body;
        })
        .catch(error => {
          return Promise.reject(error);
        });
    });
  }

  moveProjectToClient(srcClientId,dstClientId,projectId){
    return swaggerClient.swagger.then(client => {
      return client.apis.projects
        .moveProject(
          {clientId:srcClientId,projectId},
          swaggerClient.buildRequestBody({id:dstClientId}),
          {}
      );
      })
      .then(response => {
        return response.body
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }

  cloneProjectToClient(srcClientId,dstClientId,projectId){
    return swaggerClient.swagger.then(client => {
      return client.apis.projects
        .cloneProject(
          {clientId:srcClientId,projectId},
          swaggerClient.buildRequestBody({id:dstClientId}),
          {}
      );
      })
      .then(response => {
        return response.body
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }

  deleteProjectByClientId(clientId, projectId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.projects.deleteProject(
          { clientId, projectId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
