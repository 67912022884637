import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";

import i18n from "./../i18n";
import store from "./../store";

import Config from "../plugins/Config";

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  {
    path: "/",
    redirect: { name: "PagesContainer" }
  },
  {
    path: "/:lang",
    name: "PagesContainer",
    component: () => import("../views/Workspace.vue"),
    redirect: { name: "Dashboard" },
    beforeEnter(to, from, next) {
      const supportedLocaleList = Config.value("i18nSupportedLocale").split(
        ","
      );
      if (!to.params.lang) {
        const params = { ...to.params, lang: i18n.locale };
        router.replace({ name: to.name, params });
      } else {
        if (!supportedLocaleList.includes(to.params.lang)) {
          to.params.lang = i18n.locale;
          router.replace({ name: to.name, params: to.params });
        } else {
          i18n.locale = to.params.lang;
          next({ params: to.params });
        }
      }
    },
    children: [
      {
        path: "login",
        name: "Login",
        meta: {
          info: {
            title: ""
          }
        },
        component: () => import("../views/Login.vue")
      },
      {
        path: "confirm/:token",
        name: "Confirm",
        component: () => import("../views/ConfirmAccount.vue"),
        beforeEnter: (to, from, next) => {
          if (store.getters["auth/isLoggedIn"]) {
            store.commit("error/SET_ERROR", {
              titleText: "dialog.error.recovery.title",
              messageText: "dialog.error.recovery.message",
              actionBtnLabel: "button.logout",
              actionBtnCallback: () =>
                store.dispatch("logout", null, { root: true })
            });
            next({ name: "Dashboard", params: { showErrorDialog: true } });
          } else next();
        }
      },
      {
        path: "recovery/:token",
        name: "Recovery",
        component: () => import("../views/Recovery.vue"),
        beforeEnter: (to, from, next) => {
          if (store.getters["auth/isLoggedIn"]) {
            store.commit("error/SET_ERROR", {
              titleText: "dialog.error.recovery.title",
              messageText: "dialog.error.recovery.message",
              actionBtnLabel: "button.logout",
              actionBtnCallback: () =>
                store.dispatch("logout", null, { root: true })
            });
            next({ name: "Dashboard", params: { showErrorDialog: true } });
          } else next();
        }
      },
      {
        path: "password-recovery",
        name: "PasswordRecovery",
        component: () => import("../views/PasswordRecovery.vue"),
        beforeEnter: (to, from, next) => {
          if (store.getters["auth/isLoggedIn"]) {
            store.commit("error/SET_ERROR", {
              titleText: "dialog.error.recovery.title",
              messageText: "dialog.error.recovery.message",
              actionBtnLabel: "button.logout",
              actionBtnCallback: () =>
                store.dispatch("logout", null, { root: true })
            });
            next({ name: "Dashboard", params: { showErrorDialog: true } });
          } else next();
        }
      },
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("../views/Dashboard.vue"),
        meta: {
          requiresAuth: true
        },
        beforeEnter: (to, from, next) => {
          store
            .dispatch("employees/retrieveEmotivaEmployees")
            .then(() => {
              next();
            })
            .catch(() => store.dispatch("loader/close"))
            .finally(() => {});
        }
      },
      {
        path: "my-account",
        name: "Account",
        component: () => import("../views/Account.vue"),
        meta: {
          requiresAuth: true
        },
        beforeEnter(to, from, next) {
          store
            .dispatch("auth/retrieveActiveEmployeeAccountInfo")
            .then(() => {
              next();
            })
            .catch(() => store.dispatch("loader/close"))
            .finally(() => {});
        }
      },
      {
        path: "support",
        name: "Support",
        component: () => import("../views/Support.vue"),
        meta: {
          requiresAuth: false
        },
        beforeEnter(to, from, next) {
          next();
        }
      },
      {
        path: "create-user-account",
        name: "CreateUserAccount",
        meta: {
          requiresAuth: true
        },
        component: () => import("../views/CreateUserAccount.vue")
      },
      {
        path: "edit-user-account/:id",
        name: "EditUserAccount",
        meta: {
          requiresAuth: true
        },
        component: () => import("../views/EditUserAccount.vue"),
        beforeEnter: (to, from, next) => {
          const userId = to.params.id;
          store
            .dispatch("employees/retrieveEmotivaEmployee", userId)
            .then(() => {
              next();
            })
            .catch(() => store.dispatch("loader/close"))
            .finally(() => {});
        }
      },
      {
        path: "clients",
        name: "Clients",
        component: () => import("../views/Clients.vue"),
        meta: {
          requiresAuth: true
        },
        beforeEnter: (to, from, next) => {
          store
            .dispatch("clients/retrieveClients")
            .then(() => {
              next();
            })
            .catch(() => store.dispatch("loader/close"))
            .finally(() => {});
        }
      },
      {
        path: "predict-clients",
        name: "Predict-Clients",
        component: () => import("../views/ClientsPredict.vue"),
        meta: {
          requiresAuth: true
        },
        beforeEnter: (to, from, next) => {
          const clientType='predict'
          store
            .dispatch("clients/retrieveClients",clientType)
            .then(() => {
              next();
            })
            .catch(() => store.dispatch("loader/close"))
            .finally(() => {});
        }
      },
      {
        path: "services",
        name: "Services",
        component: () => import("../views/ServicesPredict.vue"),
        meta: {
          requiresAuth: true
        },
        beforeEnter: (to, from, next) => {
          store
            .dispatch("servicePredict/retrieveServices")
            .then(() => {
              next();
            })
            .catch(() => store.dispatch("loader/close"))
            .finally(() => {});
        }
      },
      {
        path: "service-details/:id",
        name: "Service-Details",
        component: () => import("../views/ServicePredictDetails.vue"),
        meta: {
          requiresAuth: true
        },
        beforeEnter: (to, from, next) => {
          const serviceId = to.params.id;
          store
            .dispatch("servicePredict/retrieveService",serviceId)
            .then(() => {
              next();
            })
            .catch(() => store.dispatch("loader/close"))
            .finally(() => {});
        }
      },
      {
        path: "service-create",
        name: "Service-Create",
        component: () => import("../views/CreatePredictService.vue"),
        meta: {
          requiresAuth: true
        },
        beforeEnter: (to, from, next) => {
          const userType='predict'
          store
            .dispatch("clients/retrieveClientsByType",userType)
            .then(() => {
              next();
            })
            .catch(() => store.dispatch("loader/close"))
            .finally(() => {});
        }
      },
      {
        path: "subscriptions",
        name: "Subscriptions",
        component: () => import("../views/SubscriptionsPredict.vue"),
        meta: {
          requiresAuth: true
        },
        beforeEnter: (to, from, next) => {
          store
            .dispatch("subscriptionPredict/retrieveSubscriptions")
            .then(() => {
              next();
            })
            .catch(() => store.dispatch("loader/close"))
            .finally(() => {});
        }
      },
      {
        path: "subscription-details/:id",
        name: "Subscription-Details",
        component: () => import("../views/SubscriptionPredictDetails.vue"),
        meta: {
          requiresAuth: true
        },
        beforeEnter: (to, from, next) => {
          const subscriptionId = to.params.id;
          store
            .dispatch("subscriptionPredict/retrieveSubscription",subscriptionId)
            .then(() => {
              next();
            })
            .catch(() => store.dispatch("loader/close"))
            .finally(() => {});
        }
      },
      {
        path: "subscription-create",
        name: "Subscription-Create",
        component: () => import("../views/CreatePredictSubscription.vue"),
        meta: {
          requiresAuth: true
        },
        beforeEnter: (to, from, next) => {
          const userType='predict'
          store
            .dispatch("clients/retrieveClientsByType",userType)
            .then(() => {
              next();
            })
            .catch(() => store.dispatch("loader/close"))
            .finally(() => {});
        }
      },
      {
        path: "create-predict-account",
        name: "CreatePedictAccount",
        meta: {
          requiresAuth: true
        },
        component: () => import("../views/CreatePredictClientAccount.vue")
      },
      {
        path: "create-client-account",
        name: "CreateClientAccount",
        meta: {
          requiresAuth: true
        },
        component: () => import("../views/CreateClientAccount.vue")
      },
      {
        path: "create-predict-client-account",
        name: "CreatePredictClientAccount",
        meta: {
          requiresAuth: true
        },
        component: () => import("../views/CreatePredictClientAccount.vue")
      },
      {
        path: "edit-client-account/:id",
        name: "EditClientAccount",
        meta: {
          requiresAuth: true
        },
        component: () => import("../views/EditClientAccount.vue"),
        beforeEnter: (to, from, next) => {
          const clientId = to.params.id;
          store
            .dispatch("clients/retrieveClient", clientId)
            .then(() => {
              next();
            })
            .catch(() => store.dispatch("loader/close"))
            .finally(() => {});
        }
      },
      {
        path: "client/:id/invoices",
        name: "ClientInvoices",
        meta: {
          requiresAuth: true
        },
        component: () => import("../views/ClientInvoices.vue"),
        beforeEnter: (to, from, next) => {
          const clientId = to.params.id;
          store
            .dispatch("billing/retrieveInvoices", clientId)
            .then(()=>{
              return store.dispatch("clients/retrieveClient", clientId)
            })
            .then(() => {
              next();
            })
            .catch(() => store.dispatch("loader/close"))
            .finally(() => {});
        }
      },

      {
        path: "client-projects/:id",
        name: "ClientProjects",
        meta: {
          requiresAuth: true
        },
        component: () => import("../views/ClientProjects.vue"),
        beforeEnter: (to, from, next) => {
          const clientId = to.params.id;
          store
            .dispatch("clients/retrieveProjectsByClientId", clientId)
            .then(() => {
              next();
            })
            .catch(() => store.dispatch("loader/close"))
            .finally(() => {});
        }
      },
      {
        path: "client/:clientId/research-project/:projectId",
        name: "ClientNewProject",
        meta: {
          requiresAuth: true
        },
        component: () => import("../views/ClientNewProject.vue"),
        beforeEnter: (to, from, next) => {
          const assetsPromise = store.dispatch(
            "projects/retrieveProjectAssets",
            {
              projectId: to.params.projectId
            }
          );
          const projectPromise = store.dispatch("clients/retrieveProject", {
            clientId: to.params.clientId,
            projectId: to.params.projectId
          });

          Promise.all([assetsPromise, projectPromise])
            .then(() => {
              next();
            })
            .catch(() => {
              store.commit("error/SET_ERROR");
            })
            .finally(() => {
              store.dispatch("loader/close");
            });
        }
      },
      {
        path: "client-projects/:clientId/project/preview/:projectId",
        name: "analytics",
        redirect: { name: "ranking" },
        component: () => import("../views/Results.vue"),
        beforeEnter(to, from, next) {
          store.commit("analysis/RESET");
          store.commit("analysis/SET_TAB", 0);
          store.commit("analysis/SET_CURRENT_VIDEO_INDEX", 0);
          store
            .dispatch("analysis/retrieveAnalysisData", {
              projectId: to.params.projectId,
              clientId: to.params.clientId
            })
            .then(() => {
              return store.dispatch(
                "clients/retrieveProjectsByClientId",
                to.params.clientId
              );
            })
            .then(() => {
              return store.dispatch("analysis/retrieveNorms");
            })
            .then(() => {
              next();
            })
            .catch(error => {
              store.dispatch("loader/close");
              console.log(error);
            });
        },
        children: [
          {
            path: "ranking",
            name: "ranking",
            component: () => import("../views/Ranking.vue"),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "score",
            name: "score",
            component: () => import("../views/Score.vue"),
            meta: {
              requiresAuth: true
            },
            beforeEnter(to, from, next) {
              store.commit("analysis/SET_CURRENT_VIDEO_INDEX", 0);
              const currentVideoIndex =
                store.getters["analysis/getCurrentVideoIndex"];
              const videoDataLength =
                store.getters["analysis/getVideoDataLength"];

              if (currentVideoIndex > videoDataLength - 1)
                store.commit("analysis/SET_CURRENT_VIDEO_INDEX", 0);

              next();
            }
          },
          {
            path: "graph",
            name: "graph",
            component: () => import("../views/Graph.vue"),
            meta: {
              requiresAuth: true
            },
            beforeEnter(to, from, next) {
              const currentVideoIndex =
                store.getters["analysis/getCurrentVideoIndex"];
              const videoDataLength =
                store.getters["analysis/getVideoDataLength"];

              if (currentVideoIndex > videoDataLength - 1)
                store.commit("analysis/SET_CURRENT_VIDEO_INDEX", 0);

              next();
            }
          },
          {
            path:"metrics-in-context",
            name:"inContextScore",
            component:()=>import("../views/InContextScore.vue"),
            meta: {
              requiresAuth: true,
            },
            beforeEnter(to, from, next) {
              // store.commit("analysis/SET_CURRENT_VIDEO_INDEX", 0);
              const currentVideoIndex =
                store.getters["analysis/getCurrentVideoIndex"];
              const videoDataLength =
                store.getters["analysis/getVideoDataLength"];
              if (currentVideoIndex > videoDataLength - 1 || currentVideoIndex <0)
                store.commit("analysis/SET_CURRENT_VIDEO_INDEX", 0);

              next();
            },
          },
        ]
      },
      {
        path: "client-projects/:clientId/project-details/:projectId",
        name: "ProjectDetails",
        meta: {
          requiresAuth: true
        },
        component: () => import("../views/ProjectDetails.vue"),
        beforeEnter: (to, from, next) => {
          const clientId = to.params.clientId;
          const projectId = to.params.projectId;

          store
            .dispatch("clients/retrieveProject", { clientId, projectId })
            .then(() => {
              next();
            })
            .catch(() => store.dispatch("loader/close"))
            .finally(() => {});
        }
      }
    ]
  },
  {
    path: "*",
    name: "404",
    beforeEnter: (to, from, next) => {
      const supportedLocaleList = Config.value("i18nSupportedLocale").split(
        ","
      );
      const splittedPath = to.path.split("/");
      if (
        splittedPath.length > 1 &&
        !supportedLocaleList.includes(splittedPath[1])
      ) {
        next({ path: `/${i18n.locale}${to.path}`, replace: true });
      } else {
        store.commit("error/SET_ERROR", {
          messageText: "dialog.error.pageNotFoundMessage"
        });
        next({ name: "Dashboard", params: { showErrorDialog: true } });
      }
    }
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    };
  }
});

router.beforeEach((to, from, next) => {
  store.commit("loader/SET_LOADER", {});
  /** CLOSE DIALOGS
   * omit:
   *    page not found error dialog
   */
  if (!to.params.showErrorDialog) {
    store.commit("error/RESET");
  }
  store.commit("confirm/RESET");
  store.commit("success/RESET");

  const isLoggedIn = store.getters["auth/isLoggedIn"];

  if (isLoggedIn && to.name === "Login") {
    next({ name: "Dashboard" });
  } else if (to.meta.requiresAuth) {
    if (isLoggedIn) {
      next();
    } else {
      next({ name: "Login", params: to.params });
    }
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  setTimeout(() => {
    store.dispatch("loader/close");
  });
});

export default router;
